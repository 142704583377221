/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import Validator from './validator';
import ValidatorLog from './validatorLog';
import ValidatorStorage from './validatorStorage';
import ValidatorSettings from './validatorSettings';
import ValidatorLiveSync from './validatorLiveSync';
import ValidatorConsole from './validatorConsole';
import ValidatorMessage from './validatorMessage';

/**
 * Apeluri catre api, online
 */
const ValidatorAjax = {
    url: function (call, silent) {
        silent = silent || false;
        if (! Validator.apiBaseUrl) {
            if (! silent) {
                Validator.initSettings(true);
                Validator.alert('invalid Base URL');
            }
            return false;
        } else {
            if (Validator.apiBaseUrl.substr(-1) == '/') {
                return Validator.apiBaseUrl+call+'/';
            } else {
                return Validator.apiBaseUrl+'/'+call+'/';
            }
        }
    },

    ping: function (silent) {
        silent = silent || false;
        var url = this.url('ping');
        if (url) {
            Validator.updateStatus(); // sterge onlineAccessId din storage
            this.send({
                url: url,
                success: function(response) {
                    if (response.result == 'success') {
                        // autoupdate
                        if (
                            response.data && response.data.updateJsApp
                            && Validator.compareVersion(response.data.updateJsApp.version, Validator.version) > 0
                        ) {
                            const urlParams = new URLSearchParams(window.location.search);
                            const skipUpdate = urlParams.get('skipUpdate') || typeof nocordova != 'undefined';               
                            if (! skipUpdate) {
                                var url = new URL(cordova.file.applicationDirectory+"www/update.html");
                                url.searchParams.set('updateVersion', response.data.updateJsApp.version);
                                url.searchParams.set('downloadUpdateUrl', encodeURI(response.data.updateJsApp.url));
                                window.location.href = url.toString();
                                return true;
                            } 
                        }

                        const liveSyncUrl = response?.data?.init?.liveSyncUrl ?? null;
                        ValidatorLiveSync.setLiveSyncUrl(liveSyncUrl);
                        ValidatorLiveSync.handleWSConnection();
                        
                        Validator.updateStatus(response.data.username, response.data.roles, response.data.accessId, response.data.accessName);
                        if (! Validator.offline) {
                            Validator.initValidate();
                            return true;
                        }
                    }
                    Validator.initLogin(false);
                },
                // adaugam handler pe eroare pentru ca trebuie sa afisam alerta chiar daca am facut apel silent
                error: function (jqXHR, textStatus, errorThrown) {
                    Validator.updateConnectionStatus('error');
                    Validator.initSettings(true);
                    Validator.alert('Request '+textStatus+': '+errorThrown);
                },
            },silent);
        }
    },

    logout: function() {
        var silent = true;
        var url = this.url('logout');
        if (url) {
            $('#login-form form').find('input[name=username], input[name=password]').val('');
            this.send({
                url: url,
                complete: function (response) {
                    ValidatorAjax.processResponse(response,silent);
                    Validator.initLogin(false);
                }
            });
        }
        ValidatorStorage.setOnlineAccessId(null);
        ValidatorLiveSync.closeWSConnection();
    },

    login: function (form) {
        var url = this.url('login');
        var form = $(form);
        if (url) {
            this.send({
                url: url,
                data: {
                    username: form.find('input[name=username]').val(),
                    password: form.find('input[name=password]').val(),
                },
                success: function (response) {
                    if (response.result == 'success') {
                        if (response.data.username) {
                            Validator.updateStatus(response.data.username, response.data.roles, response.data.accessId, response.data.accessName);
                        }
                        
                        const liveSyncUrl = response?.data?.init?.liveSyncUrl ?? null;
                        ValidatorLiveSync.setLiveSyncUrl(liveSyncUrl);
                        ValidatorLiveSync.handleWSConnection();

                        if (response.data.accessList && response.data.accessList.length>0) {
                            Validator.initAccessList(response.data.accessList);
                        } else {
                            Validator.initValidate();
                        }
                    } else {
                        // ask for password
                        Validator.initLogin(true);
                    }
                },
                complete: function (response) { 
                    ValidatorAjax.processResponse(response);
                    ValidatorAjax.syncAndImport();
                }
            }); // send
        }
    },
    
    accessList: function () {
        var url = this.url('accessList');
        if (url) {
            this.send({
                url: url,
                success: function (response) {
                    if (response.result == 'success') {
                        if (response.data.accessList) {
                            Validator.initAccessList(response.data.accessList);
                            return true;
                        } else {
                            Validator.alert('No access list');
                        }
                    }
                    // TODO: cred ca putem resnunta la initializarea explicita a ecranului de setari, va ramane activ ecranul curent inainte de apelul accessList
                    Validator.initSettings(true);
                }
            }); // send
        }
    },

    statsPage: function () {
        var url = this.url('statsPage');
        if (url) {
            this.send({
                url: url,
                loaderLingerTime: 300, // afiseaza loader-ul inca putin, pentru a lasa timp browserului sa se incarce
                success: function (response) {
                    if (response.result == 'success') {
                        if (response.data.statsUrl) {
                            window.open(response.data.statsUrl, '_blank', 'location=yes');
                        } else if (response.data.html) {
                            var b = Validator.show('#stats-page',true);
                            b.find('.container').html(response.data.html);
                        } else {
                            Validator.alert('No stats page available');
                        }
                    }
                }
            }); // send
        }
    },
   
    syncAndImport: function () {
        /*
        if (! Validator.user) {
            return false;
        }
        if (! ValidatorStorage.getOnlineAccessId()) {
            return false;
        }
        */
        // daca avem user setat si accessId setat pe sesiunea curenta pentru a putea face importul
        // pornim sincronizarea de loguri si apoi cascadat si importul de coduri noi
        Validator.loader(true,'Sync scans...');
        ValidatorAjax.syncScans({
            complete: function () {
                Validator.syncInProgress = false;
                Validator.loader(true,'Import codes...');
                ValidatorAjax.importOffline({
                    complete: function (response) {
                        ValidatorAjax.processResponse(response,true);
                        Validator.loader(false);
                    }
                }); 
            } // complete
        }); // syncScans
        return true;
    },

    syncScans: function(newOptions) {
        if (Validator.syncInProgress) {
            return false;
        }
        var silent = true; // tot timpul sincronizarea se face in background, deci nu dam niciun alert
        var url = this.url('syncScans',silent);
        if (! url) {
            return;
        }
        Validator.syncInProgress = true;
        var logDump = new ValidatorLog().getLogDump();
        if ((logDump && logDump != '') || newOptions) {
            newOptions = newOptions || {};
            console.log('sync scans (Kb)', logDump.length/1024);
            var myOptions = {
                url: url,
                data: {log:logDump},
                success: function (response) {
                    Validator.updateConnectionStatus();
                    if (response.result == 'success' && response.data && response.data.synced) {
                        new ValidatorLog().cleanLogDump();
                        ValidatorConsole.log(`Sync: ${response.data.synced} scan actions sent to server`);
                        console.log('done', response.data.synced);
                    } else if (response.alert) {
                        console.log('syncScans error',response.alert); // sync e slientios nu afiseaza alerte
                    }
                },
                error: function (jqXHR, textStatus, errorThrown) {
                    console.log('syncScans error',jqXHR, textStatus, errorThrown);
                    Validator.updateConnectionStatus('error');
                },
                complete: function (response) {
                    Validator.syncInProgress = false;
                    ValidatorAjax.processResponse(response,silent);
                }
            };
            $.extend(true, myOptions, newOptions);
            this.send(myOptions, silent);
        } else {
            Validator.syncInProgress = false;
        }
    },

    importOffline: function (newOptions) {
        // Acquire lock for WS templates
        ValidatorLiveSync.isLocked = true;

        if (Validator.syncInProgress) {
            Validator.alert('Sync in progress, wait for sync to finish');
            ValidatorLiveSync.isLocked = false; // Release lock

            return false;
        }
        var silent = true; // mesajele se afiseaza din afara metodei 
        var url = this.url('importOffline',silent);
        if (url) {
            newOptions = newOptions || {};
            var myOptions = {
                url: url,
                success: function (response, textStatus, xhr) {
                    console.log('import size (Kb)',xhr.responseText.length/1024);
                    if (response.result == 'success') {
                        ValidatorStorage.reset();
                        if (response.data.codes && response.data.codes.length>0) {
                            ValidatorStorage.save(response.data.accessId, response.data.accessName, response.data.codes, response.data.templates);
                        }
                    } else if (response.alert) {
                        ValidatorLiveSync.isLocked = false; // Release lock

                        console.log('importOffline error',response.alert); // importul e silentios, nu afiseaza alerte
                    }
                }
            };
            $.extend(true, myOptions, newOptions);
            this.send(myOptions, silent);
        }
        
        ValidatorLiveSync.isLocked = false; // Release lock
    },
    
    validate: function(form, code) {
        ValidatorMessage.updateMessage(false);
        Validator.lastScanMode = form == null || typeof(form) == 'undefined' ? 'auto' : 'manual';
        Validator.lastOnlineValidationStatus = 'none';
        var form = form || $('#validate-form form');
        var code = code || $(form).find('input[name=code]').val();
        var ts = Validator.getTime();
        
        if (! code) {
            return;
        }

        Validator.lastCode = code;
        Validator.loader();
        ValidatorMessage.updateMessage(false);
        $(form).find('input[name=code]').val('').trigger('change').blur(); // trigger change pentru ca sa se schimbe denumirea butonului de scan, iar blur pentru a ascunde tastatura pe mobil
        if (Validator.offline || Validator.hasScansInQueue) {
            ValidatorStorage.validate(code,ts);
        } else {
            var url = this.url('validate');
            if (url) {
                this.send({
                    url: url,
                    data: { code: code },
                    timeout: Validator.ajaxTimeout,
                    success: function (response) {
                        Validator.updateConnectionStatus();
                        Validator.initValidate();
                        if (response.result == 'success') { // apel facut cu succes, nu implica daca codul e valid sau nu
                            Validator.lastOnlineValidationStatus = 'ok';
                            var msg = response.data.message || [];
                            var msgLayout = response.data.messageLayout || 'success';
                            var canUndo = response.data.canUndo || false;
                            if (msgLayout == 'success' || msgLayout == 'warning') {
                                var data = ValidatorStorage.getCodeData(code);
                                var smsg = data.msg && data.msg.length ? data.msg : msg;
                                ValidatorStorage.setCodeData(null, code, 1, smsg); // incrementam scanarile din storage ptr code
                            } else if (msgLayout == 'error') {
                                ValidatorStorage.setCodeData(null, code, null); // sterge code din storage daca il gaseste
                            }
                            ValidatorMessage.updateMessage(response.data, msgLayout, code, 'validate', canUndo)
                            Validator.show('#validate-form');
                        } else {
                            // orice eroare de procesare in online va declansa validare pe storage
                            console.log('ajax response error');
                            Validator.lastOnlineValidationStatus = 'error';
                            Validator.updateConnectionStatus('error');
                            ValidatorStorage.validate(code,ts); // dam raspuns din storage
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('ajax call error', jqXHR, textStatus, errorThrown);
                        console.log('Validator.ajaxTimeout', Validator.ajaxTimeout);
                        Validator.lastOnlineValidationStatus = 'error';
                        Validator.updateConnectionStatus('error');
                        ValidatorStorage.validate(code,ts); // dam raspuns din storage
                    }
                });
            }
        }
    },

    validateCancel: function(code) {
        ValidatorMessage.updateMessage(false);
        var ts = Validator.getTime();
        if (Validator.offline || Validator.hasScansInQueue) {
            ValidatorStorage.validateCancel(code,ts);
        } else {
            var url = this.url('validateCancel');
            if (url) {
                this.send({
                    url: url,
                    data: { code: code },
                    timeout: Validator.ajaxTimeout,
                    success: function(response) {
                        Validator.updateConnectionStatus();
                        Validator.initValidate();
                        response.data = response.data || {};
                        if (response.result == 'success') { // request facut cu succes, nu se refera la anulare
                            var msg = response.data.message || [];
                            var msgLayout = response.data.messageLayout || 'success';
                            if (msgLayout == 'success') {
                                var data = ValidatorStorage.getCodeData(code);
                                var smsg = data.msg && data.msg.length ? data.msg : msg;
                                ValidatorStorage.setCodeData(null, code, 0, smsg); // setam pe 0 si incercam sa pastram mesajul
                            } else if (msgLayout == 'warning') {
                                var data = ValidatorStorage.getCodeData(code);
                                var smsg = data.msg && data.msg.length ? data.msg : msg;
                                ValidatorStorage.setCodeData(null, code, 1, smsg); // setam ca validat si incercam sa pastram mesajul
                            } else if (msgLayout == 'error') {
                                ValidatorStorage.setCodeData(null, code, null); // sterge
                            }
                            
                            ValidatorMessage.updateMessage(response.data, msgLayout, code, 'validateCancel')
                            Validator.show('#validate-form');
                        } else {
                            console.log('ajax response error');
                            Validator.updateConnectionStatus('error');
                            ValidatorStorage.validateCancel(code,ts);
                        }
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log('ajax call error',jqXHR, textStatus, errorThrown);
                        console.log('Validator.ajaxTimeout=',Validator.ajaxTimeout);
                        Validator.updateConnectionStatus('error');
                        ValidatorStorage.validateCancel(code,ts);
                    }            
                });
            }
        }
    },

    processResponse: function (response,silent) {
        silent = silent || false;
        if (response.responseJSON) { // in complete event
            response = response.responseJSON;
        }
        if (response.ts) {
            Validator.setTime(response.ts);
        }
        if (response.data) {
            if (isInt(response.data.codesTotal) || isInt(response.data.scannedTotal)) {
                var total = (isInt(response.data.codesTotal) ? response.data.codesTotal : null);
                var scanned = (isInt(response.data.scannedTotal) ? response.data.scannedTotal : null);
                ValidatorStorage.updateCount(total, scanned);
            }
            if (response.data.init) {
                var i;
                for (i in response.data.init) {
                    Validator[i] = ValidatorSettings.beforeSet(i, false) ? response.data.init[i] : ValidatorSettings.get(i);
                }
                ValidatorStorage.updateAjaxTimeout();
            }
        }
        if (response.eval) {
            eval(response.eval);
        }
        if (response.alert) {
            if (silent) {
                console.log(response.alert);
            } else {
                Validator.alert(response.alert);
            }
        }
    },
   
    send: function (customOptions,silent) {
        customOptions = customOptions || {};
        silent = silent || false;
        if (! silent) {
            Validator.loader(true);
        }
        var options = {
            type: 'POST',
            url: null,
            data: {
                version: Validator.version,
                ts: Validator.getTime(),
                accessId: ValidatorStorage.getOnlineAccessId(),
                deviceId: Validator.getDeviceId(),
            },
            success: function (response) {
                Validator.updateConnectionStatus();
            },
            error: function (jqXHR, textStatus, errorThrown) {
                Validator.updateConnectionStatus('error');
                if (! silent) {
                    Validator.initSettings(true);
                    Validator.alert('Request '+textStatus+': '+errorThrown);
                }
            },
            complete: function (response) {
                ValidatorAjax.processResponse(response,silent);
                if (customOptions.loaderLingerTime) {
                    setTimeout(Validator.loader, customOptions.loaderLingerTime, false);
                } else {
                    Validator.loader(false);
                }
            },
            dataType: 'json',
            crossDomain: true,
            xhrFields: { withCredentials: true },
            async: true
        }

        $.extend(true,options,customOptions);
        $.support.cors = true;

        return $.ajax(options);
    },
}

export default ValidatorAjax;
