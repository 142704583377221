/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import Validator from "./validator";
import ValidatorTemplate from "./validatorTemplate";

const ValidatorMessage = {

    // setari ce pot fi suprascrise din orice raspuns ajax prin response.data.init.numeSetare, valabile doar pana la restart
    msgTimeout: 10000, // milisecunde pana ascundem mesajul de validare

    /**
     * Operatii de afisare care modifica html-ul din anumite ecrane 
     */
    msgTimeoutHandler: null,
    restartScanTimeoutHandler: null,

    msgError: 'Access DENIED',
    msgWarning: 'Ticket already USED',
    msgCancelled: 'Ticket reactivated',
    msgValidated: 'Access OK',

    // Order of displaying the keys of message object
    // Be cautios, i think JS sorts the object keys A-Z, so order of sending from back is not preserved. 
    preferredMessageDisplayOrder: [
        {label: 'ticketName'},
        {label: 'customerName'},
        {label: 'seatDetail'},
    ],

    updateMessage: function (responseData, messageLayout, code, action, canUndo, isOffline = false) {
        var splash = $('#splash'),
            response = $('#response');
        if (this.msgTimeoutHandler) {
            clearTimeout(this.msgTimeoutHandler);
        }
        if (action == 'recall' && Validator.lastCode) {
            splash.hide();
            response.show();
            return false;
        } else if (! responseData) {
            splash.show();
            response.hide();
            return false;
        }

        splash.hide();
        if (action == 'validate') {
            if (Validator.playSound) {
                switch (messageLayout) {
                case 'success':
                    Validator.beepOk.playclip();
                    break;
                case 'error':
                default:
                    Validator.beepFail.playclip();
                    break;
                }
            }
            if (this.restartScanTimeoutHandler) {
                clearTimeout(this.restartScanTimeoutHandler);
            }
            this.restartScanTimeoutHandler = setTimeout(
                function () {
                    Validator.restartScanner();
                },
                this.msgTimeout
            );
            
            // this.playAudio(messageLayout);
        }
            
        var codeDisplay = code.split(':')[0] || code;
        var source = code.split(':')[1] || null;

        response.html('');
        response.attr('class', 'container');
        response.addClass(messageLayout);
        if (isOffline) {
            response.addClass('offline');
        }

        if (responseData?.profileImageThumbUrl) {
            response.append(
                '<p>' +
                '<img onclick="ValidatorMessage.zoomProfileImage(event)" class="profile-image"' +
                    ' src="' + responseData.profileImageThumbUrl + '"' +
                    ' data-big-image="' + responseData?.profileImageUrl + '"' +
                    ' />' +
                '</p>'
            );
        }
        if (code) {
            response.append('<span class="code">' + codeDisplay.toUpperCase() + '</span>');
        }
        if (messageLayout == 'error') {
            response.append(`<p class="error">${responseData?.statusMessage ?? this.msgError}</p>`);
        } else if (messageLayout == 'warning') {
            response.append(`<p class="warning">${responseData?.statusMessage ?? this.msgWarning}</p>`);
        } else if (messageLayout == 'success') {
            if (action == 'validate') {
                response.append(`<p class="success">${responseData?.statusMessage ?? this.msgValidated}</p>`);
            } else if (action == 'validateCancel') {
                response.append(`<p class="success">${responseData?.statusMessage ?? this.msgCancelled}</p>`);
            }
        }

        if (responseData) {
            if (Validator.showFullInfo) {
                // Show all the keys in the specific order
                this.preferredMessageDisplayOrder.forEach(item => {
                    if (item.label in responseData) {
                        if (item.label === 'ticketName') {
                            response.append('<p>' + ValidatorTemplate.parse(responseData[item.label]) + '</p>');
                        } else {
                            response.append('<p>' + responseData[item.label] + '</p>');
                        }
                    }
                })

            } else {
                if (responseData?.ticketName) {
                    response.append('<p>' + ValidatorTemplate.parse(responseData?.ticketName) + '</p>');
                }

                // mesaje extra ascunse
                var el = $('<div id="msgExtra" style="display:none;">');
                let showMoreFlag = false;
                
                // Show the rest in the specific order
                this.preferredMessageDisplayOrder.forEach(item => {
                    if (item.label in responseData && item.label !== 'ticketName') {
                        el.append('<p>' + responseData[item.label] + '</p>');
                        showMoreFlag = true;
                    }
                })

                if (showMoreFlag) {
                    response.append('<div class="more"><a href="#" onclick="$(this).hide();$(\'#msgExtra\').show();return false;">...</a></div>');
                    response.append(el);
                }
            }
        }
        // BUTON ANULARE
        canUndo = canUndo || false;
        if (canUndo && code && messageLayout == 'warning' && (action == 'validate' || action == 'validateCancel')) { // nu stim daca vor mai fi si alte actiuni...
            response.append('<input type="submit" class="u-full-width button" onclick="ValidatorAjax.validateCancel(\''+code+'\');return false;" value="Undo" />');
        }
        response.show();
        this.msgTimeoutHandler = setTimeout(
            function () {
                ValidatorMessage.updateMessage(false);
            },
            this.msgTimeout
        );
    },

    zoomProfileImage: function (event) {
        const modal = document.getElementById('profile-image-modal');
        const modalImg = document.getElementById('modal-profile-image');
        modal.style.display = 'flex';
        modalImg.src = event.target.dataset.bigImage;
    },

    closeProfileImageModal: function () {
        const modal = document.getElementById('profile-image-modal');
        modal.style.display = 'none';
    }
}

export default ValidatorMessage;
