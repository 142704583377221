const ValidatorConsole = {
    logArray: [],
    maxSize: 100,

    log: function (message) {
        const timestamp = new Date().toISOString();
        const logEntry = { message, timestamp };

        if (this.logArray.length >= this.maxSize) {
            this.logArray.shift();
        }
        this.logArray.push(logEntry);
    },

    getLogs: function () {
        return this.logArray;
    }
};

export default ValidatorConsole;
