/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

/**
 * Gestionam setarile
 */
const ValidatorSettings = {
    _prefix: 's.', // 's' de la 'setari'
    values: {
        'showScan' : true,
        'showFullInfo' : false,
        'playSound' : true,
        'multiScan' : false,
        'msgTimeout' : 10000,
        'ajaxTimeout' : '',
        'apiBaseUrl' : '',
        'checkpointId' : '',
        'onlineAccessId' : '',
        'offlineAccessId' : '',
        'offlineAccessName' : '',
    },

    get: function (name) {
        return this.values[name];
    },

     /**
      * 
      * @param {string} name 
      * @param {string} value 
      * @param {boolean} useraction 
      * @returns {undefined}
      */
    set: function (name, value, useraction) {
        if (! ValidatorSettings.beforeSet(name, useraction)) {
            return;
        }
          
        this.values[name] = value;
        localStorage.setItem(this._prefix + name, JSON.stringify(value)); // localStorage tine doar string-uri
        var meta = {
            name: name,
            date:new Date().toString(),
            useraction : typeof(useraction) == 'undefined' ? false : useraction
        };
        localStorage.setItem(this._prefix + name+'Meta', JSON.stringify(meta)); // localStorage tine doar string-uri
    },
    
    /**
     * daca in meta unei variabile gasesc ca ultima data a fost salvata de user, dar se incearca salvarea ei automata, 
     * intorc false, astfel incat sa se pastreze valoarea salvata de user
     * @param {string} name 
     * @param {boolean} useraction 
     * @returns {boolean}
     */
    beforeSet: function(name, useraction) {
        if (useraction) {
            return true;
        }
        var metaKey =  this._prefix + name+'Meta';
        var meta = localStorage.getItem(metaKey);
        if (meta == null) {
            return true;
        }
        try {
            meta= JSON.parse(meta);
            return ! meta.useraction;
        } catch (err) {
            return true;
        }
    },

    // pt. setarile mostenite din versiuni < 2.2.0,
    // inainte, setarile se salvau cu prefix 'c.v.', prin clasa ValidatorStorage
    // si din cauza asta se suprascriau la orice sync
    fixStorage: function () {
        for (var i in this.values) {
            var v = localStorage.getItem('c.v.' + i);
            if (v !== null ) {
                localStorage.setItem(this._prefix + i, v);
                localStorage.removeItem('c.v.' + i);
            }
        }
    },

    load: function () {
        var v;
        for (var i in this.values) {
            v = localStorage.getItem(this._prefix + i);
            if (v !== null ) {
                try {
                    this.values[i] = JSON.parse(v);
                } catch (err) {
                    this.values[i] = v; // pt cazul in care setarea nu e json
                }
            }
        }
    },

    save: function () {
        for (var i in this.values) {
            localStorage.setItem(this._prefix + i, JSON.stringify(this.values[i]));
        }
    },

    clear: function () {
        for (var i in this.values) {
            localStorage.removeItem(this._prefix + i);
        }
    },

}

export default ValidatorSettings;
