/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import ValidatorLiveSync from "./validatorLiveSync";

/**
 * Template-uri de mesaje codificate sub forma '[nume:idx]'
 */
const ValidatorTemplate = {
    _prefix: 't.',

    /**
     * Inlocuieste string-urile de forma '[t:idx]' cu textul corespunzator din template-urile salvate.
     * Se foloseste pentru tipurile de bilete.
     */
    parse: function (str)
    {
        var reg = /(\[([^:]+):([\d]+)\])/mig; // [t:12]
        do {
            var m = reg.exec(str);
            if (m) {
                var tpl = m[1];
                var tplName = m[2];
                var tplIdx = m[3];
                var txt = this.text(tplName,tplIdx);
                if (txt) {
                    str = str.replace(new RegExp(tpl.replace('[','\\[').replace(']','\\]'), "mig"), txt);
                } else {
                    console.log('invalid template',tpl);
                }
                reg.lastIndex = m.index+1;
            }
        } while (m);
        return str;
    },

    text: function (name, idx)
    {
        var set = localStorage.getItem(this._prefix+name);
        if (set) {
            set = JSON.parse(set);
            if (typeof set[idx] != 'undefined') {
                return set[idx];
            }
        }
        return false;
    },

    save: function (templates)
    {
        var name, set;
        if (templates) {
            for (name in templates) {
                set = templates[name];
                try {
                    localStorage.setItem(this._prefix+name,JSON.stringify(set));
                } catch (err) { console.log(err); }
            }
        }
    },

    get: function (name)
    {
        var set = localStorage.getItem(this._prefix+name);
        if (set) {
            set = JSON.parse(set);
            return set
        }
        return false;
    },
    /**
     * @param array receivedCodes 
     * @param array receivedTemplates 
     * @param int maxWaitTime milliseconds
     * @returns
     */
    mergeReceivedCodes: async function (receivedCodes, receivedTemplates, maxWaitTime = 10000) {
        const startTime = Date.now();

        while (ValidatorLiveSync.isLocked) {
            // Check if the maximum wait time has been exceeded
            if (Date.now() - startTime >= maxWaitTime) {
                ValidatorLiveSync.isLocked = false;
                console.warn('Lock timeout exceeded!');
            }

            // Wait until the lock is released
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        // acquire the lock
        ValidatorLiveSync.isLocked = true;
        
        try {
            // existing templates
            const templates = ValidatorTemplate.get('t');
            receivedCodes.forEach(code => {
                const idx = code[1].match(/\[t:(.*?)\]/)[1];
                const ticketType = receivedTemplates[idx];
                
                let templateIdx = Object.keys(templates).find(key => templates[key] === ticketType);
                if (! templateIdx) {
                    // get max of existing keys and add 1
                    const nextIdx = Math.max(...Object.keys(templates).map(key => parseInt(key))) + 1;
                    
                    // add this template to templates
                    templates[nextIdx] = ticketType;
                    templateIdx = nextIdx;

                    ValidatorTemplate.save({t: templates});
                }

                code[1] = code[1].replace(`[t:${idx}]`, `[t:${templateIdx}]`);
            });
        
        } catch (error) {
            ValidatorLiveSync.isLocked = false;
            console.error('Error saving new codes or templates:', error);
            return [];

        } finally {
            ValidatorLiveSync.isLocked = false;
        }

        return receivedCodes;
    }
}

export default ValidatorTemplate;
