/* global Validator */

/**
 * Este apelata la initializare de catre aplicatia de iOS
 */
function onIBPhoneReady(appBundleId)
{
    // app "IB Validator"
    if (appBundleId == 'ro.imedia.ibvalidator') {
        // do nothing

    // app "iaBilet"
    } else if (appBundleId == 'ro.imedia.iabilet' || appBundleId === undefined) {
        $("body").addClass("is-iabilet-ios-app");
        $("#back-btn").on('click', function () {
            try {
                Phone.closeModal();
            } catch (e) { alert(e) }
        });
    }

    Validator.startScanner = function () {
        if (Validator.restartScanTimeoutHandler) {
            clearTimeout(Validator.restartScanTimeoutHandler);
        }
        Phone.openScanner('', 'scanCallback', 'errorCallback');
    }
}


function scanCallback(code)
{
    Validator.scanCallback(code);
}


function errorCallback(message)
{
    ValidatorMessage.updateMessage({'message': message}, 'error', '', 'validate');
}


export {
    onIBPhoneReady,
    scanCallback,
    errorCallback,
};
