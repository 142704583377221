/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import Validator from './validator';
import ValidatorLog from './validatorLog';
import ValidatorSettings from './validatorSettings';
import ValidatorTemplate from './validatorTemplate';
import ValidatorMessage from './validatorMessage';

/**
 * Gestionam codurile stocate offline si alte informatii
 */
const ValidatorStorage = {
    _prefix: 'c.', // 'c' de la 'coduri'

    updateCount: function(total, scanned) {
        if (isInt(total)) {
            total = parseInt(total,10);
            try {
                localStorage.setItem(this._prefix+'v.total',total);
            } catch (err) { console.log(err); }
        } else {
            total = parseInt(localStorage.getItem(this._prefix+'v.total'),10);
        }
        
        if (isInt(scanned)) {
            scanned = parseInt(scanned,10);
            try {
                localStorage.setItem(this._prefix+'v.scanned',scanned);
            } catch (err) { console.log(err); }
        } else {
            scanned = parseInt(localStorage.getItem(this._prefix+'v.scanned'),10);
        }

        if (isInt(total) || isInt(scanned)) {
            scanned = scanned || 0;
            total = total || 0;
            $('#settings-form .offline').html('(' + scanned + ')');
        } else {
            $('#settings-form .offline').html('');
        }
    },

    incScannedCount: function(inc = 1) {
        let scanned = parseInt(localStorage.getItem(this._prefix + 'v.scanned'), 10);
        scanned += inc;
        this.updateCount(null, scanned);
    },
    
    updateShowScan: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('showScan');
        }

        if (chk) {
            $('#validate-form form input[name=Scan]').show();
        } else {
            $('#validate-form form input[name=Scan]').hide();
        }
        $('.chkShowScan').prop("checked", (chk ? true : false));

        ValidatorSettings.set('showScan', chk, useraction);
    },

    updateShowFullInfo: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('showFullInfo');
        }

        $('.chkShowFullInfo').prop("checked", (chk ? true : false));
        Validator.showFullInfo = (chk ? true : false);
        ValidatorSettings.set('showFullInfo', chk, useraction);
    },
    
    updatePlaySound: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('playSound');
        }

        $('.chkPlaySound').prop("checked", (chk ? true : false));
        Validator.playSound = (chk ? true : false);
        ValidatorSettings.set('playSound', chk, useraction);
    },

    updateMultiScan: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('multiScan');
        }
        $('.chkMultiScan').prop("checked", (chk ? true : false));
        Validator.multiScan = (chk ? true : false);
        ValidatorSettings.set('multiScan', chk, useraction);
    },

    updateMessageDisplayTime: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('msgTimeout');
        }
        if (chk) {
            Validator.msgTimeout = chk;
            ValidatorSettings.set('msgTimeout', chk, useraction);
        }
    },
    
    updateAjaxTimeout: function(chk, useraction) {
        if (typeof chk == 'undefined') {
            chk = ValidatorSettings.get('ajaxTimeout');
            chk = (chk !== 'short' ? 'long' : 'short'); // localStorage intoarce doar string
        } else {
            chk = ( chk == true ? 'short' : 'long');
        }

        Validator.ajaxTimeout = (chk == 'short' ? Validator.ajaxTimeoutShort : Validator.ajaxTimeoutLong);
        ValidatorSettings.set('ajaxTimeout', chk, useraction);

        $('#adv-settings-form .chkAjaxTimeout').prop("checked", (chk == 'short' ? true : false));
        $('#adv-settings-form [data-var="ajaxTimeout"]').html(Validator.ajaxTimeout);
    },

    getApiBaseUrl: function()
    {
        return ValidatorSettings.get('apiBaseUrl');
    },

    setApiBaseUrl: function(url)
    {
        ValidatorSettings.set('apiBaseUrl', url);
    },

    getOnlineAccessId: function()
    {
        return ValidatorSettings.get('onlineAccessId');
    },

    setOnlineAccessId: function(v)
    {
        v = v || '';
        ValidatorSettings.set('onlineAccessId', v);
    },
    
    getOfflineAccessId: function()
    {
        return ValidatorSettings.get('offlineAccessId');
    },

    setOfflineAccessId: function(v)
    {
        v = v || '';
        ValidatorSettings.set('offlineAccessId', v);
    },
    
    getOfflineAccessName: function()
    {
        return ValidatorSettings.get('offlineAccessName');
    },

    setOfflineAccessName: function(v)
    {
        ValidatorSettings.set('offlineAccessName', v);
    },

    reset: function()
    {
        ValidatorSettings.load(); // citesc setarile inainte de clear
        localStorage.clear();
        ValidatorSettings.save(); // restaurez setarile
    },

    save: function(accessId, accessName, codes, templates)
    {
        if (templates) {
            ValidatorTemplate.save(templates);
        }
        
        this.setOfflineAccessId(accessId);
        this.setOfflineAccessName(accessName);
        
        this.saveCodes(codes);
    },

    saveCodes: function (codes) {
        var i,data,key,cnt,msg;
        var scanned = 0;
        
        for (i in codes) {
            key = this._prefix+codes[i][0]; // codul deja encodat
            msg = codes[i][1] || '';
            msg = msg.split('|');
            cnt = codes[i][2] || 0;
            if (cnt) {
                scanned++;
            }
            var data = this.setCodeData(key, null, cnt, msg);
            if (data.err) {
                var togo = codes.length - i;
                var msg = 'Import error: '+data.err.toString()+' / Codes saved: '+i+' / unsaved: '+togo;
                Validator.alert(msg);
                console.log('ValidatorStorage.save',msg,data.err);
                i--;
                break;
            }
        }
        this.updateCount(++i, scanned);
    },
    
    setCodeData: function (key, code, cnt, msg)
    {
        var codeDisplay = null, source = null;
        if (code) {
            codeDisplay = code.split(':')[0] || code;
            source = code.split(':')[1] || null;
        }


        key = key || this._prefix+Validator.encode(codeDisplay);
        msg = msg || [];
        if (cnt === null) {
            localStorage.removeItem(key);
            return false;
        }
        var err = null;
        try {
            var data = msg.slice(0);
            cnt = parseInt(cnt,10);
            data.unshift(cnt);
            localStorage.setItem(key, JSON.stringify(data));
        } catch (err) { console.log(err); }
        return {
            key: key,
            code: code,
            cnt: cnt,
            msg: msg,
            err: err
        }
    },

    getCodeData: function (code, key)
    {
        var codeDisplay = null, source = null;
        if (code) {
            codeDisplay = code.split(':')[0] || code;
            source = code.split(':')[1] || null;
        }

        key = key || this._prefix+Validator.encode(codeDisplay);
        var data = localStorage.getItem(key);
        var cnt = null;
        var msg = [];
        if (data) {
            data = JSON.parse(data);
            cnt = parseInt(data[0],10); // counterul
            data.splice(0,1); // pastram doar mesajele
            msg = data;
        }
        return {
            key: key,
            code: code,
            cnt: cnt,
            msg: msg
        }
    },

    validate: function (code, ts, silent)
    {
        silent = silent || false;
        if (! silent) {
            Validator.loader(true);
        }
        var data = this.getCodeData(code);
        var msgType = 'error';
        var msg = data.msg || [];
        var cnt = data.cnt || data.cnt == 0 ? data.cnt : null;
        var key = data.key || null;
        if (cnt != null) {
            if (cnt > 0) {
                msgType = 'warning';
            } else {
                msgType = 'success';
                this.incScannedCount();
            }
            // update
            cnt++;
            this.setCodeData(key, code, cnt, msg);
        }
        // pe silent inseamna ca apelam acest cod din online doar ptr a mentine storage-ul sincronizat
        // nu e nevoie sa logam sau sa afisam feedback
        if (! silent) {
            this.log('validate', code, msgType, ts);
            var canUndo = false; // pe localstorage nu este permis undo, pt. ca nu pot verifica daca userul are permisiuni
            const responseData = {
                ticketName: msg[0] ?? '',
                customerName: msg[1] ?? '',
            };
            ValidatorMessage.updateMessage(responseData, msgType, code, 'validate', canUndo, true);
            Validator.show('#validate-form');
        }
    },
    
    validateCancel: function (code, ts, silent)
    {
        silent = silent || false;
        if (! silent) {
            Validator.loader(true);
        }
        var data = this.getCodeData(code);
        var msgType = 'error';
        var msg = data.msg || [];
        var cnt = data.cnt || data.cnt == 0 ? data.cnt : null;
        var key = data.key || null;
        if (cnt != null) {
            msgType = 'success';
            // update
            cnt = 0;
            this.setCodeData(key, code, cnt, msg);
            this.incScannedCount(-1);
        }
        // pe silent inseamna ca apelam acest cod din online doar ptr a mentine storage-ul sincronizat
        // nu e nevoie sa logam sau sa afisam feedback
        if (! silent) {
            this.log('validateCancel', code, msgType, ts);
            ValidatorMessage.updateMessage({}, msgType, code, 'validateCancel', false, true);
            Validator.show('#validate-form');
        }
    },

    log: function(action, code, result, ts)
    {
        action = action == 'validate' ? '1' : '2'; // validate = 1, validateCancel = 2
        result = result == 'success' ? '1' : (result == 'warning' ? '2' : '3'); // success = 1, warning = 2, error = 3
        ts = ts || Validator.getTime();
        var line = action+','+code+','+result+','+ts+';';
        new ValidatorLog().queue(line);
    }

}

export default ValidatorStorage;
