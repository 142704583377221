import jQuery from 'jquery';
import { b_crc32, isInt } from './functions';
import Validator from './validator';
import ValidatorConsole from './validatorConsole';
import { onIBPhoneReady, scanCallback, errorCallback } from './iabilet.js';
import { Phone as PhoneMock } from './phone.js';

import './styles.less';
import ValidatorMessage from './validatorMessage.js';
import ValidatorAjax from './validatorAjax.js';

global.jQuery = jQuery;
global.$ = jQuery;
global.b_crc32 = b_crc32;
global.isInt = isInt;
global.Validator = Validator;
global.ValidatorConsole = ValidatorConsole;
global.onIBPhoneReady = onIBPhoneReady;
global.scanCallback = scanCallback;
global.errorCallback = errorCallback;
global.ValidatorMessage = ValidatorMessage;
global.ValidatorAjax = ValidatorAjax;

setTimeout(() => {
    if (typeof window.Phone === 'undefined') {
        window.Phone = PhoneMock;
    }
}, 1000);
