const Phone = {       
    alert: function (message, title) {
        alert(message);
    },

    closeModal: function () {
        // empty
    },

    openScanner: function (title, callback, callbackError) {
        console.log('openScanner: ', arguments);
    },

};

export {
    Phone
};
