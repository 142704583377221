/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import ValidatorAjax from './validatorAjax';
import ValidatorLiveSync from './validatorLiveSync';
import ValidatorStorage from './validatorStorage';
import ValidatorSettings from './validatorSettings';
import ValidatorTemplate from './validatorTemplate';
import ValidatorConsole from './validatorConsole';
import ValidatorMessage from './validatorMessage';

/**
 * UI
 */
const Validator = {
    version: '',
    versionBuild: '',

    // settings
    predefinedApiBaseUrl: null, // doar va precompleta formularul din setari, la prima rulare

    // setari ce pot fi suprascrise din orice raspuns ajax prin response.data.init.numeSetare, valabile doar pana la restart
    apiBaseUrl: 'https://www.iabilet.ro/validatorApi/', // enpoint-ul unde sunt apelate toate callurile facute de ValidatorAjax, se salveaza din ecranul settings
    liveSyncUrl: null,
    ajaxTimeout: 1000, // milisecunde pana consideram eroare la apelurile de validare / anulare
    ajaxTimeoutShort: 300,
    ajaxTimeoutLong: 1000,
    syncInterval: 5000, // milisecunde intre incercari de sincronizare
    playSound: true,
    showFullInfo: false,
    multiScan:false,

    // flags
    deviceId: null,
    debug: false,
    syncInProgress: false, // setam pe true in timpul sincronizarii pentru a nu rula mai multe in paralel sau sa facem vre-un import care ar curata localStorage
    offline: false, // tine daca validatorul e in mod offline sau nu, in functie de acest check se vor executa apeluri de sincronizare 
    hasScansInQueue: false, // daca are scanari de sincronizate
    user: false, // userul logat
    lastCode: '', // ultimul cod introdus in formular
    pauseScanner: false,

    // sounds
    beepOk: null,
    beepFail: null,
    
    init: function (opt) {
        console.log('initializing...');
        
        $.extend(true, this, opt);
        
        ValidatorSettings.fixStorage();
        ValidatorSettings.load();

        if (this.debug) {
            $('#settings-btn').addClass('debug');
        }

        this.initAudio();

        $('#settings-form .version').html(this.version);

        this.apiBaseUrl = ValidatorStorage.getApiBaseUrl();
        ValidatorStorage.updateCount();
        ValidatorStorage.updateShowScan();
        ValidatorStorage.updateShowFullInfo();
        ValidatorStorage.updatePlaySound();
        ValidatorStorage.updateAjaxTimeout();
        ValidatorStorage.updateMultiScan();
        ValidatorStorage.updateMessageDisplayTime();
        
        this.updateStatus();
        
        if (! this.apiBaseUrl) {
            this.apiBaseUrl = this.predefinedApiBaseUrl;
            ValidatorStorage.setApiBaseUrl(this.apiBaseUrl);
        }
        
        ValidatorAjax.ping(true);

        $('#settings-btn').on('click', function(event) {
            event.stopPropagation();
            Validator.initSettings();
            return false;
        });
        
        $('#settings-form .accessName').on('click', function(event) {
            event.stopPropagation();
            ValidatorAjax.accessList();
            return false;
        });
        
        $('#settings-form #btn-import').on('click', function(event) {
            event.stopPropagation();
            if (ValidatorAjax.syncAndImport()) {
                // s-a executat apelul de syn si cel de import
            } else {
                Validator.alert('Application is offline');
            }
            return false;
        });
        
        $('#settings-form #stats-btn').on('click', function(event) {
            event.stopPropagation();
            ValidatorAjax.statsPage();
            return false;
        });
        
        $('#settings-form #adv-settings-btn').on('click', function(event) {
            event.stopPropagation();
            Validator.initAdvSettings();
            return false;
        });

        $('#settings-form #console-btn').on('click', function(event) {
            event.stopPropagation();
            Validator.initConsolePage();
            return false;
        });
        
        $('#stats-page #btn-close, #adv-settings-form #btn-close').on('click', function(event) {
            event.stopPropagation();
            Validator.initValidate();
            //Validator.initSettings(true);
            return false;
        });
        
        $('#settings-form .logout, #info .logout').on('click', function(event) {
            event.stopPropagation();
            if (Validator.offline) {
                Validator.initLogin(false);
            } else {
                ValidatorAjax.logout();
            }
            return false;
        });
        
        $('.chkShowScan').on('change', function(event) {
            event.stopPropagation();
            ValidatorStorage.updateShowScan(this.checked, true);
            return false;
        });
        
        $('.chkShowFullInfo').on('change', function(event) {
            event.stopPropagation();
            ValidatorStorage.updateShowFullInfo(this.checked, true);
            return false;
        });
        
        $('.chkPlaySound').on('change', function(event) {
            event.stopPropagation();
            ValidatorStorage.updatePlaySound(this.checked, true);
            return false;
        });
        
        $('.chkAjaxTimeout').on('change', function(event) {
            event.stopPropagation();
            ValidatorStorage.updateAjaxTimeout(this.checked, true);
            return false;
        });

        $('.chkMultiScan').on('change', function(event) {
            event.stopPropagation();
            ValidatorStorage.updateMultiScan(this.checked, true);
            return false;
        });
        
        $('#settings-form form').on('submit', function (event) {
            event.stopPropagation();
            Validator.saveSettings(this);
            return false;
        });
        
        $('#login-form form').on('submit', function (event) {
            event.stopPropagation();
            ValidatorAjax.login(this);
            return false;
        });
        
        $('#login-form form input[name=Offline]').on('click', function(event) {
            event.stopPropagation();
            Validator.updateStatus();
            Validator.initValidate();
            return false;
        });
        
        $('#access-form form').on('submit', function (event) {
            event.stopPropagation();
            var accessId = $(this).find('input[name=accessId]').val();
            var accessName = $(this).find('input[name=accessName]').val();
            Validator.updateStatus(Validator.user, '', accessId, accessName);
            Validator.initValidate();
            ValidatorAjax.syncAndImport();
            return false;
        });
        
        $('#validate-form form').on('submit', function (event) {
            event.stopPropagation();
            ValidatorAjax.validate(this);
            return false;
        });
        
        $('#validate-form form input[name=code]').on('keyup change input', function () {
            var val = $(this).val();
            if (val == '') {
                $('#validate-form form input[name=Scan]').val('Scan');
            } else {
                $('#validate-form form input[name=Scan]').val('Check');
            }
        });

        $('#validate-form form input[name=Scan]').on('click', function () {
            var val = $('#validate-form form input[name=code]').val();
            if (val == '') {
                ValidatorMessage.updateMessage(false);
                Validator.startScanner();
            } else {
                $('#validate-form form').submit();
            }
        });
        
        $('#validate-form #recall').on('click', function (event) {
            event.stopPropagation();
            ValidatorMessage.updateMessage(false,false,false,'recall');
            return false;
        });
        
        $(window).on('popstate', function (e) {
            var state = e.originalEvent.state || {};
            var params = state.params || null;
            var context = state.context || 'Validator';
            var hash = state.callName || 'no-hash';
            if (state.callName) {
                document.location.hash = '#'+hash;
                if (state.callName == 'validate') {
                    params.code = '';
                }
                var _call = context+'.'+state.callName+'.call('+context+',params);';
                eval(_call);
            }
        });
        
        $(document).on('keydown', function (event) {
            if (Validator.pauseScanner) {
                return;
            }
            if (! $('#validate-form').is(':visible')) {
                return;
            }


            var input = $('#validate-form form input[name=code]');

            if (event.which == 13) {
                setTimeout(function () {
                    input.blur();
                }, 50);

                // dezativez scanner-ul pt. 1 secunda
                Validator.pauseScanner = true;
                setTimeout(function () {
                    Validator.pauseScanner = false;
                }, 1000);
                return;
            }

            if ($('#response').is(':visible')) {
                ValidatorMessage.updateMessage(false);
            }

            if (! input.is(':focus')) {
                input.focus();
            }
        });

        setInterval(function() {
            if (! Validator.offline) {
                ValidatorAjax.syncScans();
            }
        }, this.syncInterval);
        
    },

    /**
     * Box-uri care presupun ascunderea alor box-uri la afisare (Validator.show)
     */
    initSettings: function (show) {
        if (typeof show == 'undefined' || show === null) {
            show = ! $('#settings-form, #stats-page, #adv-settings-form, #console-page').is(':visible');
        }

        if (show) {
            const baseUrl = this.apiBaseUrl || this.predefinedApiBaseUrl;

            $('#settings-btn').addClass('active');
            this.pushState('initSettings');

            const settingsForm = this.show('#settings-form');
            settingsForm.find('#id-base-url').val(baseUrl);

        } else {
            $('#settings-btn').removeClass('active');
            if (this.lastScreen && this.lastScreen.length && ! this.lastScreen.is(':visible')) {
                this.show(this.lastScreen);
            } else if (this.offline) {
                this.initValidate();
            } else {
                this.initLogin(false);
            }
        }
    },

    initAdvSettings: function () {
        $('#settings-btn').addClass('active');

        Validator.show('#adv-settings-form',true)
            .find('input, [data-var], [data-call]').each(function (i, el) {
                el = $(el);
                const k = el.data('var') || el.data('call');
                if (k) {
                    if (el.is('input')) {
                        el.val(Validator[k] ?? '-');
                    } else{
                        el.html(Validator[k] ?? '-');
                    }
                }
            });

        $('button[data-setting]').off('click').on('click', function(e) {
            e.stopPropagation();
            var math = {
                '+' : function(x, y, stopVal) {return x + y <= stopVal ? x+y : stopVal;},
                '-' : function(x, y, stopVal) {return x - y >= stopVal ? x-y : stopVal;},
                '/' : function(x, y, stopVal) {return x / y >= stopVal ? x/y : stopVal;},
                '*' : function(x, y, stopVal) {return x * y <= stopVal ? x*y : stopVal;},
            }
            var el = $(this);
            var v = el.data('setting');
            var input = $('[data-var="' + v + '"]');
            var step = el.data('step');
            var stopVal = el.data('stop-value');
            var operator = el.data('operator');
            var value = parseInt(input.val());
            var result = math[operator](parseInt(value) , parseInt(step), parseInt(stopVal));
            input.val(result);
            Validator[v] = result;
            ValidatorSettings.set(v, result, true);
        });
    },
    
    initLogin: function (askPassword) {
        $('#settings-btn').show();
        Validator.offline = true;
        this.updateStatus();
        this.pushState('initLogin');

        var user = $('#login-form input[name=username]');
        var pass = $('#login-form input[name=password]');
        user.parent().show();
        if (askPassword) {
            pass.parent().show();
        } else {
            pass.parent().hide();
        }

        this.show('#login-form');
    },
    
    accessList: {}, // ultimul accessList folosit
    initAccessList: function (accessList) {
        var i, el,
            container = $('#access-form form'),
            item = $('#access-form form .row:first').clone();
        $('#access-form form .row').remove(); // remove all rows
        accessList = accessList || this.accessList;
        for (i in accessList) {
            el = item.clone();
            el.find('a')
                .attr('data-id',accessList[i].id)
                .attr('data-name',accessList[i].name)
                .html(accessList[i].name)
                .on('click',function(event) {
                    event.stopPropagation();
                    $('#access-form form input[name=accessId]').val($(this).attr('data-id'));
                    $('#access-form form input[name=accessName]').val($(this).attr('data-name'));
                    $('#access-form form').submit();
                    return false;
                })
            ;
            container.append(el);
        }
        this.accessList = accessList;
        this.pushState('initAccessList');
        this.show('#access-form');
    },
    
    initValidate: function () {
        ValidatorMessage.updateMessage(false);
        this.pushState('initValidate');
        $('#settings-btn').show();
        this.show('#validate-form');
    },

    initConsolePage: function () {
        Validator.show('#console-page', true);
        $('#settings-btn').addClass('active');

        const consoleContainer = $('#console-container');

        const refreshConsole = () => {
            const logs = ValidatorConsole.getLogs();
            consoleContainer.empty();
            if (logs.length === 0) {
                consoleContainer.append($('<p>').text('--- no messages ---'));
                return;
            }
            for (let i = logs.length - 1; i >= 0; i--) {
                const log = logs[i];
                const date = new Date(log.timestamp);
                const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' });
                const p = $('<p>').text(`[${formattedTime}] ${log.message}`);
                consoleContainer.append(p);
            }
        };
        refreshConsole();

        const refreshInterval = 3000; // 3s
        const intervalId = setInterval(() => {
            try {
                if (consoleContainer.is(':visible')) {
                    refreshConsole();
                } else {
                    clearInterval(intervalId);
                }
            } catch (error) {
                console.error(error);
                clearInterval(intervalId);
            }
        }, refreshInterval);
    },

    /**
     * ultimma scanare
     * auto = daca s-a obtinut codul in urma unei scanari, manual daca a fost scris pe input
     * iese din bucla de multiscan in cazul scanarii manuale
     */
    lastScanMode: 'auto',
    /**
     * statusul ultimei validari online
     * ok =  validare succes, none = nu s-a facut validare online, error = request error
     * iese din bucla de multiscan in caz de eroare
     */
    lastOnlineValidationStatus : 'none', 

    updateConnectionStatus: function (appstatus)
    {
        appstatus = appstatus || null;
        if (Validator.offline) {
            appstatus = 'offline';
        } else if (appstatus == null) {
            appstatus = Validator.user ? 'online' : 'offline';
        }
        $('#info user').attr('class',appstatus);
    },

    updateStatus: function (user, roles, accessId, accessName) {
        user = user || 'offline';        
        roles = roles || '';
        accessId = accessId || '';
        accessName = accessName || accessId;

        if (user && user != 'offline') {
            Validator.offline = false;
            ValidatorStorage.setOnlineAccessId(accessId);
            Validator.user = user;
        } else {
            Validator.offline = true;
            accessId = ValidatorStorage.getOfflineAccessId();
            accessName = ValidatorStorage.getOfflineAccessName();
            Validator.user = false;
        }
        /* settings box */
        $('#settings-form user').html(user);
        $('#settings-form roles').html(roles);
        $('#settings-form .accessName').html(accessName && accessName != '' ? accessName : 'SELECT');
        $('#settings-form .logout').html(Validator.offline?'Log in':'Log out');
        if (Validator.apiBaseUrl) {
            $('#settings-form .row.user, #settings-form .row.access').show();
        } else {
            $('#settings-form .row.user, #settings-form .row.access').hide();
        }
        /* info box */
        $('#info user').html(user);
        $('#info .accessName').html(accessName != '' ? accessName : '&nbsp;');
        this.updateConnectionStatus();
    },

    /**
     * ALTE OPERATII
     */
    
    saveSettings: function (form) {
        var baseUrl = $(form).find('#id-base-url').val();
        this.apiBaseUrl = baseUrl;
        ValidatorStorage.setApiBaseUrl(baseUrl);
        
        if (baseUrl) {
            ValidatorAjax.ping(); // ptr update status sau redirect la login
            $('#settings-btn').show();
        } else {
            Validator.initSettings(true); // afisam setarile prima data
        }
    },

    encode: function (code)
    {
        code = code.toLowerCase();
        return code.substr(0,2)+b_crc32(code);
    },

    alert: function (msg) {
        try {
            Phone.alert(msg);
        } catch(err) {
            alert(msg);
        }
    },

    redirect: function (url) {
        window.location = url;
    },

    lastScreen: null, // ultimul screen vizibil, util cand ne intoarcem din ecranul vizibil in prezent
    show: function (el,dontSaveLast) {
        dontSaveLast = dontSaveLast || false;
        if (! dontSaveLast) {
            this.lastScreen = $('.box:visible');
        }
        $('.box').not(el).hide();
        if (! ['settings-form', 'adv-settings-form'].includes($(el).attr('id'))) {
            $('#settings-btn').removeClass('active');
        }
        this.loader(false);
        return $(el).show();
    },

    loader: function (show, msg) {
        if (show) {
            msg = msg || '';
            $('body').css('cursor','wait');
            var loader = $('#loader');
            if (!loader.length) {
                loader = $('<div id="loader"></div>').appendTo('body');
            }
            loader.show();
            loader.html('<span>'+msg+'<span>');
        } else {
            $('body').css('cursor','default');
            return $('#loader').html('').hide();
        }
    },

    timeDiff: 0, // valoarea folosita pentru a ajusta timestampul citit de javascript, se sincronizeaza la fiecare operatie ajax
    setTime: function(ts)
    {
        this.timeDiff = new Date().getTime() - ts * 1000;
    },

    getTime: function()
    {
        return new Date().getTime() - this.timeDiff;
    },

    pushState: function (callName) {
        history.pushState({callName: callName}, callName, '#'+callName);
    },

    sndSucc: null,
    sndFail: null,
    sndCnt: 0,

    initAudio: function () {
        var audiotypes = {
            "mp3": "audio/mpeg",
                "mp4": "audio/mp4",
                "ogg": "audio/ogg",
                "wav": "audio/wav"
        };

        function ss_soundbits() {
            var audio_element = document.createElement('audio')
            if (audio_element.canPlayType){
                for (var i=0; i<arguments.length; i++){
                    var source_element = document.createElement('source')
                    source_element.setAttribute('src', arguments[i])
                    if (arguments[i].match(/\.(\w+)$/i))
                        source_element.setAttribute('type', audiotypes[RegExp.$1])
                    audio_element.appendChild(source_element)
                }
                audio_element.load()
                audio_element.playclip = function() {
                    audio_element.pause()
                    audio_element.currentTime=0
                    audio_element.play()
                };
                return audio_element
            }
        }
        this.beepOk = ss_soundbits('img/beep-ok.ogg', "img/beep-ok.mp3");
        this.beepFail = ss_soundbits('img/beep-fail.ogg', "img/beep-fail.mp3");
    },

    getDeviceId: function () {
        if (Validator.deviceId) {
            return Validator.deviceId;
        }
        const key = 'deviceId';
        Validator.deviceId = localStorage.getItem(key);

        // if not found in localStorage, generate a random deviceId
        if (! Validator.deviceId) {
            const attributes = ['sneaky', 'sleepy', 'quirky', 'zany', 'klutzy', 'snappy', 'cranky', 'fluffy', 'sassy', 'grumpy', 'bouncy', 'loopy'];
            const animals = ['fox', 'horse', 'lion', 'tiger', 'bear', 'wolf', 'eagle', 'owl', 'shark', 'whale', 'falcon', 'rabbit'];
            const attribute = attributes[Math.floor(Math.random() * attributes.length)];
            const animal = animals[Math.floor(Math.random() * animals.length)];
            const suffix = Math.random().toString(36).substring(2, 8);
    
            Validator.deviceId = `${attribute}-${animal}-${suffix}`;
    
            localStorage.setItem(key, Validator.deviceId);
        }

        return Validator.deviceId;
    },
  
    startScanner: function () {
        if (this.restartScanTimeoutHandler) {
            clearTimeout(this.restartScanTimeoutHandler);
        }
       //https://github.com/journeyapps/zxing-android-embedded
        var params =  {
            'prompt_message': 'Scaneaza codul', // Change the info message. A blank message ('') will show a default message
            'orientation_locked': true, // Lock the orientation screen
            'camera_id': 0, // Choose the camera source
            'beep_enabled': false, // Enables a beep after the scan
            'scan_type': 'normal', // Types of scan mode: normal = default black with white background / inverted = white bars on dark background / mixed = normal and inverted modes
            'barcode_formats': [
                'QR_CODE',
                'CODE_39',
                'DATA_MATRIX',
                'CODE_128'
            ], // Put a list of formats that the scanner will find. A blank list ([]) will enable scan of all barcode types
            'extras': {} // Additional extra parameters. See [ZXing Journey Apps][1] IntentIntegrator and Intents for more details
        };
        if (! window.scanner) {
            console.error('Scanner not found');
            return;
        }
        window.scanner.scan(params,
            function (s) {
                Validator.scanCallback(s.code);
            },
            function (e) {
                Validator.initValidate();
                if (e == 'cancelled' && Validator.multiScan) {
                    ValidatorMessage.updateMessage(false,false,false,'recall');
                }
            })
        ;
    },

    scanCallback: function (code) {
        if (code.length > 0) {
            ValidatorAjax.validate(null, code);
        } else {
            Validator.initValidate();
        }
    },

    restartScanner: function ()
    {
        if (! Validator.multiScan) {
            return;
        }
        
        if (! $('#validate-form').is(':visible')) {
            return;
        }
        
        if(Validator.lastScanMode != 'auto') {
            return;
        }
      
        if (Validator.lastOnlineValidationStatus == 'error') {
            return;
        }

        Validator.startScanner();
    },

    /**
     * Compare two version numbers.
     * If version1 > version2 return 1; if version1 < version2 return -1; otherwise return 0.
     * 
     * @param {string} version1
     * @param {string} version2
     * @return {number}
     */
    compareVersion: function (version1, version2)
    {
        const levels1 = version1.split('.');
        const levels2 = version2.split('.');
        const length = Math.max(levels1.length, levels2.length);
    
        for (let i = 0; i < length; i++) {
            const v1 = i < levels1.length ? parseInt(levels1[i]) : 0;
            const v2 = i < levels2.length ? parseInt(levels2[i]) : 0;
        
            if (v1 > v2) return 1;
            if (v2 > v1) return -1;
        }
    
        return 0;
    }

}

export default Validator;
