/* eslint-disable no-unused-vars */
/* eslint-disable no-redeclare */

import Validator from './validator';
import ValidatorStorage from './validatorStorage';

/**
 * gestioneaza logul de scanari executate offline
 */
const ValidatorLog = function (key, accessId) {
    var _prefix = 'l.';
    var _queueLabel = _prefix+'q';          // denumirea cozii in care bagam logarile
    var _queueAccessLabel = _prefix+'qa';   // denumirea campului in care tinem accesul cozii, daca e diferit de accesul ultimei logari atunci golim coada intr-o veriga inlantuita inainte de a adauga logarea noua
    var _queueSize = 1024;                  // la 1024 de bytes salvam coada intr-o veriga inalantuita si o golim
    var _firstKeyLabel = _prefix+'first';   // denumirea campului unde tinem prima veriga din lant
    var _lastKeyLabel = _prefix+'last';     // denumirea campului unde tinem ultima veriga din lant
    var _dumpKeys = [];                     // tinem minte temporar cheile incluse in ultimul dump, ulterior le voi sterge din lant
    accessId = accessId || ValidatorStorage.getOfflineAccessId(); // accesul folosit la scanarile offline


    return {
        qa: accessId, // pe ce accessId sunt scanarile din coada curenta
        q: '',      // coada curenta
        key: key,   // identificatorul cozii
        next: false, // veriga urmatoare din lant

        export: function() {
            return { // doar ce se va stoca la fiecare bloc de logare
                qa: this.qa,
                q: this.q,
                next: this.next
            }
        },

        // TODO: de studiat daca se merita sa renuntam la coada activa [_queueLabel] si impicit [_queueAccessLabel] si sa logam direct in ultima veriga din lant, pana se umple sau se schimba access-ul
        queue: function(line, save) {
            
            var q = localStorage.getItem(_queueLabel);
                q = q || '';
            // testam daca cumva coada incarcata avea alt accessId decat logarea curenta
            var qa = localStorage.getItem(_queueAccessLabel);
            if (qa != this.qa && q != '') {
                var ob = new ValidatorLog();
                    ob.qa = qa;
                    ob.q = q;
                    ob.save();
                q = '';
                save = false;
            }
            q += line;
            if (q.length > _queueSize || save) {
                this.q = q;
                this.save();
                q = '';
            }
            try {
                Validator.hasScansInQueue = q != '';
                //console.log('queue','Validator.hasScansInQueue = ',Validator.hasScansInQueue);
                localStorage.setItem(_queueLabel, q);
                localStorage.setItem(_queueAccessLabel, this.qa);
            } catch (err) { console.log(err); }
        },

        hasQueue: function() {
            var q = localStorage.getItem(_queueLabel);
            return q && q != '' && q != 'false';
        },

        getLogDump: function() {
            // compilam un string care sa contina toate logurile de pe toate listele de acces
            // tinem minte ce blocuri am inclus pentru a le sterge in cleanLogDump daca sincronizarea s-a facut cu succes
            
            if (this.hasQueue()) {
                this.queue('',true); // convertim coada curenta in lista inlantuita
            }
            
            _dumpKeys = [];
            var dump = [];
            var ob = new ValidatorLog().getNext();
            while (ob) {
                dump.push('['+ob.qa+']'+ob.q);
                _dumpKeys.push(ob.key);
                ob = ob.getNext();
            }
            return dump.join('|');
        },

        cleanLogDump: function() {
            // curatam logurile incluse in ultimul dump
            var ob = new ValidatorLog().getNext();
            var toSync = false;
            while (ob) {
                if ($.inArray(ob.key, _dumpKeys)) {
                    ob.remove();
                } else {
                    toSync = true; // mai am in lant blocuri care nu au fost in ultima sincronizare
                }
                ob = ob.getNext();
            }
            _dumpKeys = [];
            Validator.hasScansInQueue = toSync || this.hasQueue();
            //console.log('cleanLogDump','Validator.hasScansInQueue = ',Validator.hasScansInQueue);
        },

        getFirstKey: function() {
            var firstKey = localStorage.getItem(_firstKeyLabel);
            return firstKey && firstKey != 'false' ? firstKey : false;
        },
        setFirstKey: function(val) {
            try {
            localStorage.setItem(_firstKeyLabel,val);
            } catch (err) { console.log(err); }
        },
        getLastKey: function() {
            var lastKey = localStorage.getItem(_lastKeyLabel);
            return lastKey && lastKey != 'false' ? lastKey : false;
        },
        setLastKey: function(val) {
            try {
            localStorage.setItem(_lastKeyLabel,val);
            } catch (err) { console.log(err); }
        },
        
        load: function(key) {
            key = key || this.key;
            if (key) {
                var line = localStorage.getItem(key);
                var ob = JSON.parse(line);
                $.extend(this,ob);
                this.key = key;
                return true;
            }
        },
        
        save: function () {
            if (this.key) {
                // e obiect incarcat
            } else {
                // e obiect nou
                this.newKey();
                var lastKey = this.getLastKey();
                var firstKey = this.getFirstKey();
                if (lastKey) {
                    // updatam lastObject sa aiba nextKey spre this.key;
                    var lastLog = new ValidatorLog();
                        lastLog.load(lastKey);
                        if (lastLog.key) {
                            lastLog.next = this.key;
                            lastLog.save();
                        }
                } 
                if (! firstKey) {
                    this.setFirstKey(this.key);
                }
                this.setLastKey(this.key);
            }
            var line = JSON.stringify(this.export());
            try { 
            localStorage.setItem(this.key,line);
            } catch (err) { console.log(err); }
        },


        remove: function () {
            if (this.key) {
                var firstKey = this.getFirstKey();
                var lastKey = this.getLastKey();
                if (firstKey == this.key) {
                    this.setFirstKey(this.next);
                }
                if (lastKey == this.key) {
                    this.setLastKey(false);
                }
                localStorage.removeItem(this.key);
            }
        },

        newKey: function() {
            if (this.key) {
                return this.key;
            }
            var qid = localStorage.getItem(_prefix+'qid');
                qid = parseInt(qid,10);
                qid = qid || 0;
                qid++;
                localStorage.setItem(_prefix+'qid', qid);
            this.key = _prefix+'q'+qid;
            return this.key;
        },

        getNext: function()
        {
            var firstKey = this.getFirstKey();
            if (! this.key && firstKey) {
                var ob = new ValidatorLog(firstKey);
                    ob.load();
                    if (ob.key) {
                        return ob;
                    }
            } else if (this.next) {
                var ob = new ValidatorLog(this.next);
                    ob.load();
                    return ob;
            }
            return false;
        },

        runTest: function() {
            new ValidatorLog().testSave(true).testRemove().testSave();
        },

        testSave: function(clear) {
            if (clear) localStorage.clear();
            var cnt = parseInt(localStorage.getItem(_prefix+'testCnt'),10);
                cnt = cnt || 0;
            var i, k;
            for (i=0;i<3;i++) {
                cnt++;
                localStorage.setItem(_prefix+'testCnt',cnt);
                console.log('save x',cnt);
                var x = new ValidatorLog();
                    x.q = 'x'+cnt;
                    x.save();
                console.log(x);
                if (i==0)
                    k = x.key;
            }

            console.log('load',k);
            var x = new ValidatorLog(k);
            x.load();
            console.log(x);
            return this;
        },

        testRemove: function() {
            console.log('remove all');
            var ob = new ValidatorLog().getNext();
            while (ob) {
                console.log('remove' ,ob);
                ob.remove();
                ob = ob.getNext();
            }
            return this;
        }
    }
}

export default ValidatorLog;
