/* eslint-disable no-unused-vars */

import Validator from './validator';
import ValidatorStorage from './validatorStorage';
import ValidatorConsole from './validatorConsole';
import ValidatorTemplate from './validatorTemplate';

/**
 * Handles websocket connection via LiveSync internal product
 */
const ValidatorLiveSync = {
    socket: null,
    maxTries: 5,
    currentTry: 0,
    baseDelay: 1000, // Initial delay in miliseconds

    // Mutex to lock the saving of new codes and templates until the prior one finished
    // avoids concurrency issues when saving new templates, avoids saving two templates with the same index
    isLocked: false,

    canConnect: function () {
        if (!this.getLiveSyncUrl()) {
            return false;
        }

        if (!ValidatorStorage.getOnlineAccessId()) {
            return false;
        }

        return true;
    },

    handleWSConnection: function () {
        if (!this.canConnect()) {
            return false;
        }

        this.log('Connecting to WS...');

        try {
            var checkpointId = ValidatorStorage.getOnlineAccessId().split('_')[1];
            // Connect to the server
            const queryParams = {
                room_id: checkpointId,
                access_id: ValidatorStorage.getOnlineAccessId(),
                device_id: Validator.getDeviceId(),
            }
            const queryString = new URLSearchParams(queryParams).toString();

            this.socket = new WebSocket(`${this.getLiveSyncUrl()}?${queryString}`);

            this.socket.onopen = () => {
                this.log("Connected to WS");

                // Reset tries
                this.currentTry = 0;
            };

            this.socket.onmessage = async (event) => { // arrow function to preserve context, so "this" is ValidatorLiveSync, not Socket
                var message = JSON.parse(event.data);
                if (message.result == 'success') {
                    if (message.data.codes && message.data.codes.length > 0) {
                        this.log(`${message.data.codes.length} code(s) received`);

                        const receivedCodes = await ValidatorTemplate.mergeReceivedCodes(message.data.codes, message.data.templates.t);
                        const codesTotal = message.data.codesTotal;
                        const scannedTotal = message.data.scannedTotal;

                        ValidatorStorage.saveCodes(receivedCodes);
                        ValidatorStorage.updateCount(codesTotal, scannedTotal);
                    }
                } else {
                    this.log('WebSocket data error' + event.error);
                }
            };

            this.socket.onclose = (event) => {
                if (event.wasClean) {
                    this.log(`WebSocket closed cleanly, code=${event.code}, reason=${event.reason}`);
                    if (
                        event.code === 1001 // idle
                        // || event.code === 1000 // normal closure
                    ) {
                        this.retryWSConnection();
                    }
                } else {
                    this.log('WebSocket connection died');
                    this.retryWSConnection();
                }
            };

            this.socket.onerror = (error) => {
                this.log(`WebSocket error:`);
                this.log(error);
            };

        } catch (e) {
            this.logError(e);

            this.retryWSConnection();
        }
    },

    retryWSConnection: function () {
        if (this.currentTry < this.maxTries) {
            // Increment the current try count
            this.currentTry++;
            // Calculate the delay using exponential backoff
            const delay = Math.pow(2, this.currentTry - 1) * this.baseDelay;
            this.log(`Reconnecting in ${delay / 1000} seconds (try ${this.currentTry} of ${this.maxTries})`);
            setTimeout(() => this.handleWSConnection(), delay);
        } else {
            this.log(`Maximum tries (${this.maxTries}) reached. Stopping reconnect attempts.`);
        }
    },

    closeWSConnection: function () {
        if (this.socket) {
            this.socket.close(1000, 'Closing the connection gracefully');
        }
        this.setLiveSyncUrl(null);
    },

    getLiveSyncUrl: function () {
        return Validator.liveSyncUrl;
    },

    setLiveSyncUrl: function (url) {
        Validator.liveSyncUrl = url;
    },

    log: function (message) {
        console.log('LiveSync:', message);
        ValidatorConsole.log('LiveSync: ' + message);
    },

    logError: function (message) {
        console.error('ValidatorLiveSync:', message);
        ValidatorConsole.log('ValidatorLiveSync: ' + message);
    },
}

export default ValidatorLiveSync;
